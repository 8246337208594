<template>
    <div>
        <!-- Content -->
        <div class="sm:ml-64">
            <div class="rounded-lg mt-14">
                <b-row style="display: flex;justify-content: space-between;">
                    <div class="container col-11" v-if="type === 'CARTE-BIOMETRIQUE'">
                        <form-wizard color="#7367F0" title="" subtitle="" finish-button-text="Générer"
                            back-button-text="Précédent" next-button-text="Suivant" button-size="sm"
                            class="steps-transparent mb-3 font-size" justified @on-complete="getDatamatrix()">
                            <tab-content>
                                <validation-observer ref="personalData" tag="form">
                                    <b-row>
                                        <b-col md="12">
                                            <!-- <b-alert variant="dark" show> -->
                                            <h4 class="alert-heading">
                                                {{ type }} (Informations personnelles):
                                            </h4>
                                            <!-- </b-alert> -->
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Nom de famille:" label-for="taille">
                                                <validation-provider #default="{ errors }" name="nom de famille"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="emission" v-model="form.nomDeFamille" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Prénom:" label-for="prenom">
                                                <validation-provider #default="{ errors }" name="prenom"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="prenom" v-model="form.prenom" size="sm" type="text"
                                                        :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Numéro d'identification
                                                  personnel:" label-for="numeroIdentificationPersonnel">
                                                <validation-provider #default="{ errors }"
                                                    name="numeroIdentificationPersonnel"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="numeroIdentificationPersonnel"
                                                        v-model="form.numeroIdentificationPersonnel" size="sm" type="text"
                                                        :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Nationalité:" label-for="nationalite">
                                                <validation-provider #default="{ errors }" name="nationalite"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="nationalite" v-model="form.nationalite" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Date de naissance:" label-for="dateDeNaissance">
                                                <validation-provider #default="{ errors }" name="date de naissance"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="dateDeNaissance" v-model="form.dateDeNaissance"
                                                        size="sm" type="date" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Selectionnez votre genre:" label-for="sexe">
                                                <validation-provider #default="{ errors }" name="sexe"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <select v-model="form.sexe" id="sexe"
                                                        class="form-control select-size-sm">
                                                        <option value="M">Masculin</option>
                                                        <option value="F">Féminin</option>
                                                    </select>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Groupe sanguin:" label-for="groupe sanguin">
                                                <validation-provider #default="{ errors }" name="groupe sanguin"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="groupeSanguin" v-model="form.groupeSanguin" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Lieu de naissance:" label-for="lieunaissance">
                                                <validation-provider #default="{ errors }" name="lieu de naissance"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="lieunaissance" v-model="form.lieuDeNaissance"
                                                        size="sm" type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </validation-observer>
                            </tab-content>
                            <tab-content>
                                <validation-observer ref="cardData" tag="form">
                                    <b-row>
                                        <b-col md="12">
                                            <!-- <b-alert variant="dark" show> -->
                                            <h4 class="alert-heading">
                                                Informations liées à la carte:
                                            </h4>
                                            <!-- </b-alert> -->
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Autorité de délivrance de la carte:" label-for="autorite">
                                                <validation-provider #default="{ errors }" name="autorité de délivrance"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="autorite" v-model="form.autoriteDeDelivrance"
                                                        size="sm" type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Numéro professionnel:" label-for="numpro">
                                                <validation-provider #default="{ errors }" name="numéro professionnel"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="numpro" v-model="form.numeroProfessionnel" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Numéro de la carte:" label-for="numerocarte">
                                                <validation-provider #default="{ errors }" name="numéro de la carte"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="numerocarte" v-model="form.numeroDeCarte" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Profession:" label-for="profession">
                                                <validation-provider #default="{ errors }" name="profession"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="profession" v-model="form.profession" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Date d'expiration:" label-for="expiration">
                                                <validation-provider #default="{ errors }" name="date de d'expiration"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="expiration" v-model="form.dateExpiration" size="sm"
                                                        type="date" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </validation-observer>
                            </tab-content>
                        </form-wizard>
                    </div>
                    <div class="container col-11" v-else-if="type === 'TITRE-FONCIER'">
                        <form-wizard color="#7367F0" title="" subtitle="" finish-button-text="Générer"
                            back-button-text="Précédent" next-button-text="Suivant" button-size="sm"
                            class="steps-transparent mb-3 font-size" justified @on-complete="getDatamatrixTf()">
                            <tab-content>
                                <validation-observer ref="personalData" tag="form">
                                    <b-row>
                                        <b-col md="12">
                                            <!-- <b-alert variant="dark" show> -->
                                            <h4 class="alert-heading">
                                                {{ type }} (Partie 1):
                                            </h4>
                                            <!-- </b-alert> -->
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Numéro de la propriété:" label-for="numeroPropriete">
                                                <validation-provider #default="{ errors }" name="numéro de propriété"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="emission" v-model="titrefoncierform.numeroPropriete"
                                                        size="sm" type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Nom du propriétaire:" label-for="nom">
                                                <validation-provider #default="{ errors }" name="nom"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="nom" v-model="titrefoncierform.nomProprietaire" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Adresse du propriétaire:" label-for="adresseProprietaire">
                                                <validation-provider #default="{ errors }"
                                                    name="adresseProprietaire"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="adresseProprietaire"
                                                        v-model="titrefoncierform.adresseProprietaire" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Description de la propriete:" label-for="descriptionPropriete">
                                                <validation-provider #default="{ errors }" name="description propriete"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="descriptionPropriete" v-model="titrefoncierform.descriptionPropriete"
                                                        size="sm" type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Numéro de la parcelle:" label-for="numeroParcelle">
                                                <validation-provider #default="{ errors }" name="numéro de parcelle"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="numeroParcelle"
                                                        v-model="titrefoncierform.numeroParcelle" size="sm" type="text"
                                                        :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </validation-observer>
                            </tab-content>
                            <tab-content>
                                <validation-observer ref="cardData" tag="form">
                                    <b-row>
                                        <b-col md="12">
                                            <!-- <b-alert variant="dark" show> -->
                                            <h4 class="alert-heading">
                                                Partie 2/2:
                                            </h4>
                                            <!-- </b-alert> -->
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Date d'acquisition" label-for="dateAcquisition">
                                                <validation-provider #default="{ errors }" name="date d'acquisition"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="dateAcquisition"
                                                        v-model="titrefoncierform.dateAcquisition" size="sm"
                                                        type="date" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Droits de propriété:" label-for="droitsProprietes">
                                                <validation-provider #default="{ errors }" name="droits de propriété"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="droitsProprietes" v-model="titrefoncierform.droitsProprietes"
                                                        size="sm" type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Restriction de Propriétés:" label-for="restrictionPropriete">
                                                <validation-provider #default="{ errors }" name="restriction de Propriétés"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="restrictionPropriete" v-model="titrefoncierform.restrictionPropriete"
                                                        size="sm" type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Nom du notaire:" label-for="profession">
                                                <validation-provider #default="{ errors }" name="nomNotaire"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="nomNotaire" v-model="titrefoncierform.nomNotaire"
                                                        size="sm" type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Références légales:" label-for="referencesLegales">
                                                <validation-provider #default="{ errors }" name="date de d'expiration"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="referencesLegales" v-model="titrefoncierform.referencesLegales"
                                                        size="sm" type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </validation-observer>
                            </tab-content>
                        </form-wizard>
                    </div>
                    <div class="container col-11" v-else-if="type === 'DIPLOME'">
                        <form-wizard color="#7367F0" title="" subtitle="" finish-button-text="Générer"
                            back-button-text="Précédent" next-button-text="Suivant" button-size="sm"
                            class="steps-transparent mb-3 font-size" justified @on-complete="getDatamatrixDiplome()">
                            <tab-content>
                                <validation-observer ref="personalData" tag="form">
                                    <b-row>
                                        <b-col md="12">
                                            <!-- <b-alert variant="dark" show> -->
                                            <h4 class="alert-heading">
                                                {{ type }} (Informations personnelles):
                                            </h4>
                                            <!-- </b-alert> -->
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Nom de famille:" label-for="taille">
                                                <validation-provider #default="{ errors }" name="nom de famille"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="emission" v-model="diplomeform.nom" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Prénom:" label-for="prenom">
                                                <validation-provider #default="{ errors }" name="prenom"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="prenom" v-model="diplomeform.prenoms" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Date de naissance:" label-for="dateDeNaissance">
                                                <validation-provider #default="{ errors }" name="date de naissance"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="dateDeNaissance" v-model="diplomeform.dateDeNaissance"
                                                        size="sm" type="date" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Lieu de naissance:" label-for="lieunaissance">
                                                <validation-provider #default="{ errors }" name="lieu de naissance"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="lieunaissance" v-model="diplomeform.lieuDeNaissance"
                                                        size="sm" type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </validation-observer>
                            </tab-content>
                            <tab-content>
                                <validation-observer ref="cardData" tag="form">
                                    <b-row>
                                        <b-col md="12">
                                            <!-- <b-alert variant="dark" show> -->
                                            <h4 class="alert-heading">
                                                Informations liées au diplôme:
                                            </h4>
                                            <!-- </b-alert> -->
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Autorité de délivrance de la carte:" label-for="autorite">
                                                <validation-provider #default="{ errors }" name="autorité de délivrance"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="autorite" v-model="diplomeform.ecole" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Niveau:" label-for="niveau">
                                                <validation-provider #default="{ errors }" name="niveau"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <select v-model="diplomeform.niveau" id="niveau"
                                                        class="form-control select-size-sm">
                                                        <option value="Licence">Licence</option>
                                                        <option value="Master">Master</option>
                                                        <option value="Doctorat">Doctorat</option>
                                                    </select>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Nom su signataire:" label-for="signataire">
                                                <validation-provider #default="{ errors }" name="numéro de la carte"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="signataire" v-model="diplomeform.signataire" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Lieu d'obtention:" label-for="lieu">
                                                <validation-provider #default="{ errors }" name="lieu d'obtention"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="lieu" v-model="diplomeform.lieuDobtention" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Spécialité:" label-for="specialite">
                                                <validation-provider #default="{ errors }" name="specialite"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="specialite" v-model="diplomeform.specialite" size="sm"
                                                        type="text" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Mention:" label-for="mention">
                                                <validation-provider #default="{ errors }" name="mention"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <select v-model="diplomeform.mention" id="mention"
                                                        class="form-control select-size-sm">
                                                        <option value="passable">Passable</option>
                                                        <option value="assez-bien">Assez-bien</option>
                                                        <option value="bien">Bien</option>
                                                        <option value="tres-bien">Très-bien</option>
                                                    </select>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Date d'obtention:" label-for="obtention">
                                                <validation-provider #default="{ errors }" name="date de d'obtention"
                                                    :rules="{ required: { allowFalse: false } }">
                                                    <b-form-input id="obtention" v-model="diplomeform.dateObtention"
                                                        size="sm" type="date" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </validation-observer>
                            </tab-content>
                        </form-wizard>
                    </div>
                    <div class="container col-11 card py-2" v-else-if="type === 'DOWNLOAD'">
                        <h4 class="alert-heading">
                            Téléchargez VOTRE CEV
                        </h4>
                        <div class="d-flex flex-row align-items-center justify-content-center">
                            <img :src="imageReadlink" alt="" class="me-75 m-4" height="200" width="200" v-if="loader">
                        </div>
                        <a class="btn btn-success" style="width: max-content;" :href="imagelink"
                            download="cachet-electronique-visible">Télécharger</a>
                    </div>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { heightTransition } from '@core/mixins/ui/transition'

import {
    reactive, onMounted, watch, ref
} from '@vue/composition-api'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    required, email, confirmed, password, image,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import useStructureRegister from '@/services/authentification/registerStructureService'
// eslint-disable-next-line import/no-extraneous-dependencies
import { French } from 'flatpickr/dist/l10n/fr'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'
// import formStep from './components/formStep.vue'
import VueDragResize from 'vue-drag-resize'
import router from '@/router'

import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton, VBModal, BAlert, BFormFile, BCard, BForm
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import useAbonnements from '@/services/admin/abonnementService'
// eslint-disable-next-line import/no-cycle
import useTypeDocument from '@/services/admin/typeDocumentService'
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        FormWizard,
        TabContent,
        BRow,
        // formStep,
        BCol,
        BFormGroup,
        BFormFile,
        BFormInput,
        BAlert,
        BCard,
        BForm,
        BButton,
        vSelect,
        VueDragResize,
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    mixins: [heightTransition],

    data() {
        return {
            required,
            password,
            email,
            confirmed,
            image,
        }
    },
    setup() {
        const {
            getClientAccess, checkAbonnement, abonnements, abonnementProcess,
        } = useAbonnements()
        const {
            imageUrl, handleImageSelected, uploadFile, fileName, deleteFile,
        } = useImageUpload()
        const {
            getCategorieDocuments, validate, generate, c40data, imagelink,imageReadlink, loader
        } = useTypeDocument()
        onMounted(async () => {
            getCategorieDocuments()
            type.value = router.currentRoute.params.type.toUpperCase()
        })
        const submittedNames = []

        const items = reactive([])
        const pageNum = ref(1)

        const type = ref(null)
        const canvasContainer = reactive({
            width: null,
            height: null,
        })
        const form = reactive({
            type_document: '1',
            taille: 0,
            nomDeFamille: '',
            prenom: "",
            numeroIdentificationPersonnel: "",
            nationalite: "",
            dateDeNaissance: "",
            lieuDeNaissance: "",
            autoriteDeDelivrance: "",
            dateExpiration: "",
            numeroDeCarte: "",
            sexe: "",
            profession: "",
            numeroProfessionnel: "",
            groupeSanguin: "",
        })
        const diplomeform = reactive({
            type_document: '2',
            taille: 0,
            prenoms: '',
            nom: "",
            dateDeNaissance: "",
            lieuDeNaissance: "",
            numeroDuDiplome: "",
            ecole: "",
            niveau: "",
            signataire: "",
            lieuDobtention: "",
            specialite: "",
            mention: "",
            dateObtention: "",
        })

        const titrefoncierform = reactive({
            type_document: '3',
            taille: 0,
            numeroPropriete: '',
            nomProprietaire: "",
            adresseProprietaire: "",
            descriptionPropriete: "",
            numeroParcelle: "",
            dateAcquisition: "",
            droitsProprietes: "",
            restrictionPropriete: "",
            nomNotaire: "",
            referencesLegales: "",
            issueDate: "",
        })
        const removeVal = async data => {
            form[data] = ''
        }
        const form1 = reactive({
            champ: '',
            value: '',
            pdf: []
        })
        // 
        const getDatamatrix = async () => {
            await validate({ ...form })
            type.value = 'DOWNLOAD'
            // await queuePurchase(c40data.value)
        }
        const getDatamatrixDiplome = async () => {
            await validate({ ...diplomeform })
            type.value = 'DOWNLOAD'
            // await queuePurchase(c40data.value)
        }
        const getDatamatrixTf = async () => {
            await validate({ ...titrefoncierform })
            type.value = 'DOWNLOAD'
            // await queuePurchase(c40data.value)
        }
        const config = {
            wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'j M  Y',
            altInput: true,
            dateFormat: 'Y-m-d',
            locale: French, // locale for this instance only
        }
        return {
            abonnements,
            config,
            checkAbonnement,
            form,
            submittedNames,
            items,
            getDatamatrix,
            getDatamatrixDiplome,
            getDatamatrixTf,
            removeVal,
            getClientAccess,
            handleImageSelected,
            generate,
            c40data,
            validate,
            form1,
            titrefoncierform,
            diplomeform,
            fileName,
            loader,
            abonnementProcess,
            getCategorieDocuments,
            imagelink,
            imageReadlink,
            canvasContainer,
            pageNum,
            type,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    mounted() {
        this.initTrHeight()

    },
    methods: {
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmitModal()
        },
        formatter(value) {
            return value.toLowerCase()
        },
        repeateAgain() {
            this.items.push({
                id: this.nextTodoId += this.nextTodoId,
            })
            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight)
            })
        },
        removeItem(index, champ) {
            this.items.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
            this.removeVal(champ)
        },
        initTrHeight() {
            this.trSetHeight(null)
            // this.$nextTick(() => {
            //   this.trSetHeight(this.$refs.form.scrollHeight)
            // })
        },
        validationForm() {
            return new Promise((resolve, reject) => {
                this.$refs.accountRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        download() {
            const pdfUrl = this.imagelink;
            // Create a hidden anchor element to trigger the download.
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.target = '_blank'; // Opens in a new tab
            link.download = 'cachet-electronique-visible'; // Optional: Specify the file name
            link.click();
            // const options = 'location=center,resizable=0,width=1024,height=630,popup'
            // window.open('https://ecev-appose.mameribj.org?n=' + pdfUrl.split('CEVs/')[1], '_blank', options)
        },
        validationIsConditionForm() {
            return new Promise((resolve, reject) => {
                this.$refs.accountConditionRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        validationPersonnalForm() {
            return new Promise((resolve, reject) => {
                this.$refs.accountPersonnalRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        validationFilesForm() {
            return new Promise((resolve, reject) => {
                if (this.form.fichiers.length >= 2) {
                    resolve(true)
                } else {
                    reject()
                }
            })
        },
        validationEntrepriseForm() {
            return new Promise((resolve, reject) => {
                this.$refs.accountEntrepriseRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        handleSubmitModal() {
            this.$refs.accountFilesRules.validate().then(success => {
                if (success) {
                    // Push the name to submitted names
                    // this.queuePurchase(this.piece)
                    this.$refs['file-input'].reset()
                    // Hide the modal manually
                    this.$nextTick(() => {
                        this.$refs['my-modal'].toggle('#toggle-btn')
                    })
                }
            })
        },

    },

}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
